<template>
  <base-view icon="folder" title="Unidades administrativas">
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>

    <check-authorization
      :requiresAuthorizations="['crear unidades administrativas']"
      :overrideIf="$store.getters['administrativeUnitModule/isEditingAdministrativeUnit']"
    >
      <administrative-unit-form
        ref="administrativeUnitForm"
        @hideForm="showForm = false"
        v-show="showForm || isEditingAdministrativeUnit"
      />
    </check-authorization>

    <check-authorization :requiresAuthorizations="['listar unidades administrativas']">
      <div class="card card-header-actions">
        <div class="card-header">
          Listado de unidades administrativas
          <button v-show="!showForm" class="btn btn-success btn-sm ml-auto" @click="cancel()">
            {{ !showForm ? 'Crear' : 'Ocultar' }}
          </button>
        </div>

        <!-- Contenedor -->
        <div class="card-body">
          <administrative-unit-list @hideForm="showForm = true"></administrative-unit-list>
        </div>
      </div>
    </check-authorization>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import AdministrativeUnitForm from '@/components/AdministrativeUnits/AdministrativeUnitForm'
import AdministrativeUnitList from '@/components/AdministrativeUnits/AdministrativeUnitList'

export default {
  name: 'AdministrativeUnits',

  components: {
    AdministrativeUnitForm,
    AdministrativeUnitList
  },

  computed: {
    ...mapGetters('administrativeUnitModule', [
      'isEditingAdministrativeUnit',
      'hasAdministrativeUnits'
    ])
  },

  data () {
    return {
      showForm: false
    }
  },

  methods: {
    cancel () {
      this.showForm = !this.showForm
    }
  }
}
</script>
