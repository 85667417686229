<template>
  <div>
    <x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" />

    <b-overlay :show="loading">
      <template #overlay>
        <loading />
      </template>

      <b-table
        :fields="fields"
        :items="items"
        striped
        bordered
        responsive
        hover
        stacked="lg"
        show-empty
      >
        <template #cell(estatus)="row">
          <b-badge :variant="getStatusVariant(row.item.estatus)">
            {{ getStatusText(row.item.estatus) }}
          </b-badge>
        </template>

        <template #cell(acciones)="row">
          <u-d-controls
            :id="row.item.id"
            editPath="administrativeUnitModule/getAdministrativeUnitToEdit"
            deletePath="administrativeUnitModule/deleteAdministrativeUnit"
            :editPermissions="['editar unidades administrativas']"
            :deletePermissions="['eliminar unidades administrativas']"
            @on-got-item="up"
          ></u-d-controls>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AdministrativeUnitList',

  data () {
    return {
      fields: [
        { key: 'nombre', label: 'Nombre' },
        { key: 'clave', label: 'Clave' },
        { key: 'periodo_fiscal', label: 'Periodo fiscal' },
        { key: 'unidad_administrativa', label: 'Dependencia a otra unidad' },
        { key: 'estatus', label: 'Estatus' },
        { key: 'acciones', label: 'Acciones' }
      ],

      reset: false,

      loading: false
    }
  },

  computed: {
    ...mapGetters('administrativeUnitModule', { items: 'getAdministrativeUnits' })
  },

  methods: {
    ...mapActions('administrativeUnitModule', ['getAdministrativeUnits']),

    async onChangePeriodoFiscal (periodo_fiscal_id) {
      this.loading = true
      const { error, message } = await this.getAdministrativeUnits(periodo_fiscal_id)
      this.loading = false

      if (error) return this.$notify({ error, message }, 'Listado de unidades administrativas')
    },

    getStatusVariant (status) {
      return status === 1 ? 'success' : 'danger'
    },

    getStatusText (status) {
      return status === 1 ? 'Activo' : 'Inactivo'
    },

    up () {
      window.scrollTo(0, 50)
      this.$emit('hideForm')
    }
  }
}
</script>
